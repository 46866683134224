import React from "react";
import { Container, Col, Row, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from "react-lazy-load-image-component";

import slash from "../statics/images/icons/slash.png"
export default function Breadcrump(props) {

    return (
        <Container className="px-0 d-md-block d-none d-flex flex-row">
            {
                props.previews_links.map((link, index) => {
                    return(
                        <div className="d-inline-block" key={"breadcrump-"+index}>
                            {index > 0 ? <LazyLoadImage className="px-1" src={slash}/>:""}
                            <Link className="breadcrump-item" to={link.route}>{link.label}</Link>
                        </div>
                    )
                })
            }
        </Container>

    );
}