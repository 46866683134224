import React, { Component } from "react"

import { Container, Col, Row } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Error404 = () => {

    return (
        <Container className="p-5">
            <h1 className="pt-5">Error404</h1>
            <div className="mx-5 p-5">
                <iframe width="1129" height="636" src="https://www.youtube.com/embed/o3l9zEEXmGU?autoplay=1" title="Spider Cochon, Spider Cochon [RE-UPLOAD]" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>
        </Container>
    )
}

export default Error404