import { Container, Col, Row, Badge } from 'reactstrap'
import React, { Component } from "react"

import axios from "axios"
import { LazyLoadImage } from 'react-lazy-load-image-component';

import productImage from '../statics/images/assets/product.png'
class SpecialProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: null
        }
    }

    componentDidMount() {
        this.getProducts(this.props.productsIds)
    }
    componentDidUpdate() {
        if(this.state.products === null)
            this.getProducts(this.props.productsIds)
    }

    async getProducts(ids) {
        const url = this.props.config.apiUrl + 'product/'
        let newProducts = []
        ids.forEach(async id => {
            try {
                const response = await axios.get(url + id)
                newProducts.push(response.data)

            } catch (error) {
                console.log(error)
            }
        });
        this.setState({
            products: newProducts
        })

    }

    render() {
        return (
            <>

                {
                    this.state.products != null ?


                        <Row className="pt-3 pb-5">
                            {
                                this.state.products.map((product) => {

                                    return (
                                        <Col key={"key-product-"+product.id.toString()} lg={4} className="">
                                            <div className="my-card h-100">
                                                <div className="p-4 d-flex flex-column h-100">
                                                    <div>
                                                        {this.props.badge ? <Badge className="my-badge text-uppercase bg-biopredic" color="" pill>Chemical product</Badge> : <></>}
                                                        <p className={this.props.badge ? "py-3 m-0": "pb-3 m-0"}><b>{product.name}</b></p>
                                                        <LazyLoadImage className="w-100" src={product.images.length > 0 ? product.images[0].src : productImage} />
                                                        <p className="small text-muted pb-5">{product.short_description.replace("<p>", "").replace("</p>", "")}</p>
                                                    </div>
                                                    <p className="small mt-auto mb-0 "><b>À partir de {product.regular_price}€ HT - <span className="text-grey">48,90€ TTC</span></b></p>

                                                </div>
                                            </div>
                                        </Col>
                                    )

                                })

                            }

                        </Row> : <></>

                }
            </>

        );
    }
}

export default SpecialProducts