import React, { Component, useEffect, useState, useRef } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import logo from '../statics/images/logos/wepredic.png'
import axios from "axios"


const Register = (props) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmedPw, setConfirmedPw] = useState("")
    const [company, setCompany] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [isCGV, setIsCGV] = useState(false)

    const [formValid, setFormValid] = useState(false)
    const [message, setMessage] = useState("")

    const emailRef = useRef("")


    useEffect(() => {

    })

    const register = async () => {
        const url = props.config.apiUrl
        let data = {
            email: email,
            first_name: firstname,
            last_name: lastname,
            username: email.substring(0, email.indexOf("@")),
            company: company,
            password: password
        }
        await axios.post(url + "customers", { body: data })
    }

    const handleChange = () => {
        console.log(emailRef.current.value)
        console.log(firstname)
        console.log(lastname)
        console.log(company)
        console.log(password)
        console.log(isCGV)

        if (password.length < 12 || password === null || password != confirmedPw) {
            setFormValid(false)
            setMessage("Saisie du mot de passe incorrecte. Pour rappel le mot de passe doit contenir au minimum 12 caractères.")
            return 0
        }
        if (!isCGV) {
            setFormValid(false)
            setMessage("Saisie du mot de passe incorrecte. Pour rappel le mot de passe doit contenir au minimum 12 caractères.")
            return 0
        }

        if (isCGV && password.length >= 12 && password === confirmedPw && validateEmail(email) && firstname.length > 0 && lastname.length > 0 && company.length > 0) {
            setFormValid(true)
            return 0
        }

    }


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const changeEmail = (e) => {
        setEmail(e.target.value)
        handleChange()
    }
    const changePassword = (e) => {
        setPassword(e.target.value).then(() => { handleChange() })
    }
    const changeConfirmedPw= (e) => {
        setConfirmedPw(e.target.value).then(() => { handleChange() })
    }
    const changeCompany = (e) => {
        setCompany(e.target.value).then(() => { handleChange() })
    }
    const changeLastname = (e) => {
        setLastname(e.target.value).then(() => { handleChange() })
    }
    const changeFirstname = (e) => {
        setFirstname(e.target.value).then(() => { handleChange() })
    }
    const changeIsCGV = (e) => {
        setIsCGV(e.target.value).then(() => { handleChange() })
    }


    return (
        <div className="pt-5">
            <Container className="py-4">
                <div className="w-100 text-center pt-5">
                    <div className="w-50 mx-auto">
                        <LazyLoadImage width={60} height={"auto"} src={logo} />
                        <h2 className="part-title pb-0 pt-2 text-dark"><b>Ravi de vous rencontrer</b></h2>
                        <p className=" pt-0 ">To get information about our products and services,
                            or to simply meet us, don’t hesitate to contact us.</p>
                    </div>
                </div>
                <Row className="pt-3">
                    <Col md={4}></Col>
                    <Col md={4}>
                        <p className="text-center text-muted mb-1">Create your Wepredic Member profile and ....Dire aux utilisateurs pourquoi cela vaut la peine de s’inscrire (même si c’est une étape obligatoire).</p>
                        <p className="text-center text-muted small p-0 m-0">Ex: Indiquer sous forme de liste à puces, un, deux ou trois avantages majeurs. “Payer en ligne. Suivez les étapes de traitement de vos commandes”.</p>
                        <Form onSubmit={() => { register() }} className="pt-4">
                            <FormGroup>
                                <Label className="mb-0 small text-muted">E-mail</Label>
                                <Input ref={emailRef} onChange={()=>handleChange()} type="email" required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Password</Label>
                                <Input value={password} onChange={(e) => { changePassword() }} type="password" required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Confirm password</Label>
                                <Input value={confirmedPw} onChange={(e) => { changeConfirmedPw() }} type="password" required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">First name</Label>
                                <Input value={firstname} onChange={(e) => { changeFirstname() }} type="text" required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Last name</Label>
                                <Input value={lastname} onChange={(e) => { changeLastname() }} type="text" required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Company's name</Label>
                                <Input value={company} onChange={(e) => { changeCompany() }} type="text" required />
                            </FormGroup>
                            <FormGroup>
                                <Input value={isCGV} onChange={(e) => { changeIsCGV() }} type="checkbox" />
                                <Label className="small text-muted">You agree to Wepredic's <a className="text-dark" href="">Privacy policy</a> and <a className="text-dark" href="">Terms of use</a>.</Label>
                            </FormGroup>
                            <FormGroup>
                                <PrimaryButton customClasses="w-100 mt-1" type="submit" disable={!formValid} content="Create my account" theme={formValid ? "1" : "7"} />
                            </FormGroup>
                            <div className="w-100 text-center">
                                <p className="small text-grey">Already an account? <a className="text-dark" href="">Sign in</a></p>
                            </div>
                        </Form>
                    </Col>
                </Row>



            </Container>
        </div>
    )
}

export default Register