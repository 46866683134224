import React, { useEffect } from "react";
import { DNA } from 'react-loader-spinner'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import PrimaryButton from "./primary-button";


const ConfirmOrder = (props) => {

    const navigate = useNavigate()

    const gotToNewPage = (route) => {
        navigate(route);
    }


    const validate = () => {
        props.setStateRoot({
            orderConfirmed: false
        }, () => { })
    }

    const showHideClassName = props.show ? "modal d-block" : "modal d-none";

    return (
        <div className={showHideClassName}>
            <div className="modal-main py-5 text-center">
                <h2>Order confirmed</h2>
                <PrimaryButton action={() => validate()} content="Ok" theme="1" />
            </div>
        </div>

    )
}

export default ConfirmOrder