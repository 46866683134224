import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import SpecialProducts from "../components/special-products.jsx"
import Breadcrump from "../components/breadcrump";
import CartClass from "../classes/cart"
import withRouter from '../components/with-router';
import Cookies from 'universal-cookie'

import axios from "axios"
import product from "./product.jsx";



const Order = (props) => {

    const [email, setEmail] = useState("g.queau@wepredic.com")
    const [phone, setPhone] = useState("0644131075")
    const [firstname, setFirstname] = useState("gauthier")
    const [lastname, setLastname] = useState("Quéau")
    const [company, setCompany] = useState("")
    const [tva, setTva] = useState("")
    const [address, setAddress] = useState("bretêche")
    const [country, setCountry] = useState("France")
    const [city, setCity] = useState("Rennes")
    const [postcode, setPostcode] = useState("35000")
    const navigate = useNavigate()

    const gotToNewPage = (route) => {
        navigate(route);
    }

    const createOrder = async (e) => {
        e.preventDefault()
        const url = props.config.apiUrl
        const items = []
        props.cart.items.map((element) => {
            items.push({
                "product_id": element.id,
                "quantity": element.quantity
            })
        });
        const data = {
            firstname: firstname,
            lastname: lastname,
            address: address,
            city: city,
            postcode: postcode,
            country: country,
            email: email,
            phone: phone,
            items: items
        }

        try {

            await axios.post(url + "customers", { body: data }).then(response => {

                axios.post(url+ 'orders/', { body: data }).then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        props.setStateRoot({
                            orderConfirmed: true
                        }, () => { })
                        props.resetCart()
                        gotToNewPage("")
                    }
                })
            })

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="pt-5">

            <Container className="py-4">
                <Row className="pt-5 gx-5">
                    <Col md={9}>

                        <h4 className="py-5">Where should we send your order?.</h4>

                        <h5 className="py-3">What’s yout contact information?</h5>
                        <Form onSubmit={(e) => { createOrder(e) }}>
                            <FormGroup >
                                <Label className="small my-0 py-0 text-muted">Email Address</Label>
                                <div className="d-flex " >
                                    <Input className="me-md-4" type="email" required />
                                    <p className="mb-0">We’ll email you a receipt and send order updates to your email.</p>
                                </div>
                            </FormGroup>

                            <FormGroup className="py-2">
                                <Label className="small my-0 py-0 text-muted">Phone number</Label>
                                <div className="d-flex " >
                                    <Input className="me-md-4" type="tel" required />
                                    <p className="mb-0">The phone number will allow the delivery person to contact you.</p>
                                </div>
                            </FormGroup>

                            <h5 className="py-3">Enter your name and address:</h5>
                            <FormGroup >
                                <Label className="small my-0 py-0 text-muted">First name</Label>
                                <Input className="me-md-4" type="firstname" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">Last Name</Label>
                                <Input className="me-md-4" type="lastname" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">Company</Label>
                                <Input className="me-md-4" type="company" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">TVA number</Label>
                                <Input className="me-md-4" type="text" />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">Street address</Label>
                                <Input className="me-md-4" type="address" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">Country</Label>
                                <Input className="me-md-4" type="country" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">City</Label>
                                <Input className="me-md-4" type="city" required />
                            </FormGroup>
                            <FormGroup className="pt-2">
                                <Label className="small my-0 py-0 text-muted">Postcode</Label>
                                <Input className="me-md-4" type="Postcode" required />
                            </FormGroup>

                            <div className="d-flex flex-row justify-content-between">
                                <Link className="text-wepredic my-auto underline-0" to={"/cart"}>Back to card</Link>
                                <PrimaryButton type="submit" customClasses="radius-small py-2 my-auto" theme="1" content="Request a quote" />
                            </div>
                        </Form>


                    </Col>
                    <Col md={3}>
                        <h3 className="pb-3">In your cart</h3>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Items total</p>
                            <p className="small text-muted">{props.cart.total} €</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Shipping cost</p>
                            <p className="small text-muted">7,50 €</p>
                        </div>

                        <hr className="mt-1 mb-3" />
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Total</p>
                            <p className="small text-muted"><b>130,50 €</b></p>
                        </div>
                        <hr className="mt-1 mb-0" />
                        <p className="pt-4">Livraison d'ici le ven. 29 oct.</p>

                    </Col>
                </Row>

            </Container>
        </div>
    )


}

export default Order