import React, { Component, useRef, useEffect, useState, createRef } from "react"

import { Container, Col, Row, Badge } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom'
import useSize from "../utils/useSize.js";
import CountUp from "react-countup";

import PrimaryButton from "../components/primary-button.jsx";
import ProductsPreview from "../components/products-preview.jsx";
import SpecialProducts from "../components/special-products.jsx"
import hero from "../statics/images/assets/starlight-home.png"
import admet from "../statics/images/assets/admet-crop.png"
import pichet from "../statics/images/assets/pichet.png"
import scientist from "../statics/images/assets/scientist.png"
import logo from "../statics/images/logos/eurosafe-light.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Product from "./product.jsx";


class Eurosafe extends Component {


    constructor(props) {
        super(props);
        this.state = {
            height: 0,
        };
    }

    componentDidMount() {
        this.setState({
            height: window.innerHeight
        })
    }
    componentDidUpdate() {
        if (window.innerHeight != this.state.height) {
            this.setState({
                height: window.innerHeight
            })
        }
    }



    render() {

        return (
            <div className="eurosafe">
                <div className="window bg-white text-center" style={{ height: this.props.isSticky ? this.state.height : (this.state.height - 60) }}>
                    <Container className="h-100" >
                        <Row className="h-100" >
                            <Col md={4}></Col>
                            <Col md={4} className="align-items-center d-flex justify-content-center h-100">
                                <div>
                                    <h2 className="title">Eurosafe</h2>
                                    <p className="subtitle">Fine chemistry solutions designed by chemists for toxicologists.</p>
                                    <div>
                                        <LazyLoadImage style={{ width: "40%" }} src={hero} />
                                    </div>
                                    <PrimaryButton customClasses={"m-0"} action={() => { }} content="Buy" theme="5" />
                                </div>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                    </Container>
                </div>



                <Container className="py-5">
                    <h3 className="part-title py-4 text-dark ">Starlight products. Best sellers & news</h3>
                    <SpecialProducts badge={false} config={this.props.config} productsIds={[1366, 1360, 1355]} />
                </Container>

                <div className="bg-starlight py-5">
                    <Container className="py-3">
                        <h3 className="part-title"><span className="text-grey">We are a</span> One-stop-shop for worldwide Toxicology.<br />
                            <span className="text-grey">We provide high quality chemicals in single or libraries formats.</span>
                        </h3>
                        <Row className="py-3">
                            <Col lg={4} className="">
                                <div className="my-card h-100">
                                    <div className="py-5 px-4">
                                        <h5>ADMET Products</h5>
                                        <p className="text-small text-muted py-2">+ 200 diluted or powdered chemical <br />products.
                                        </p>
                                    </div>
                                    <div className="card-image ps-4">
                                        <LazyLoadImage className="w-100" src={admet}></LazyLoadImage>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="my-card h-100">
                                    <div className="py-5 px-4">
                                        <h5>ADMET Librairy</h5>
                                        <p className="text-small text-muted py-2">Librairies designed for Absorption,<br />Disposition, Metabolism, Elimination and<br />Toxicity domains.
                                        </p>
                                    </div>
                                    <div className="card-image ps-4 text-end pe-5">
                                        <LazyLoadImage className="w-50" src={pichet}></LazyLoadImage>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="my-card h-100">
                                    <div className="py-5 px-4">
                                        <h5>On-demand chemistry</h5>
                                        <p className="text-small text-muted py-2">Chemical biology solutions designed<br />products.
                                        </p>
                                    </div>
                                    <div className="card-image ps-4 text-end pe-5">
                                        <LazyLoadImage className="w-25" src={scientist}></LazyLoadImage>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>


                <Container className="py-5">
                    <Row className="py-3">
                        <Col>
                            <h3 className="part-title text-dark "><span className="text-starlight">Simply</span> focus on toxicology <br />
                                We manage sourcing <span className="text-starlight">complexity</span></h3>
                        </Col>
                        <Col>
                            <p className="py-2">
                                A One-stop-shop for ADMET chemicals<br />
                                <span className="text-grey">Commodities, innovative products and libraries all available in one shop</span>
                            </p>

                            <p className="py-2">
                                Ready-to-use chemical products<br />
                                <span className="text-grey">We weight, we dilute for you, we combine, you focus on your experimentation</span>
                            </p>

                            <p className="py-2">
                                “Fresh” products for reliable results<br />
                                <span className="text-grey">Buying small quantities avoid issues of long-term stability</span>
                            </p>

                            <p className="py-2">
                                “Green attitude”- No waste<br />
                                <span className="text-grey">Getting just what you need avoid sur-stock and product destruction</span>
                            </p>

                        </Col>
                    </Row>
                </Container>

                <div className="bg-starlight position-relative" style={{ zIndex: 0 }}>
                    <div className="h-100 w-25 position-absolute bg-starlight-dark"></div>
                    <Container className="bg-starlight-dark position-relative h-100 p-0" style={{ zIndex: 0 }}>
                        <div className="position-absolute h-100 w-100 d-flex flex-row" style={{ zIndex: -1, left: 0 }}>
                            <div className="h-100 border-start border-light" style={{ width: "15%" }}></div>
                            <div className="h-100 border-start border-light" style={{ width: "15%" }}></div>
                            <div className="h-100 border-start border-light" style={{ width: "20%" }}></div>
                            <div className="h-100 border-start border-light" style={{ width: "30%" }}></div>
                            <div className="h-100 border-start border-light bg-starlight" style={{ width: "20%", boxSizing: "none" }}></div>
                        </div>
                        <div className="pt-4"></div>
                        <h3 className="part-title text-light py-5 w-25">On-demand chemistry chronology</h3>
                        <div className="w-100 py-5 d-flex flex-row text-white" style={{ zIndex: 1 }}>
                            <div className="d-flex flex-row" style={{ width: "15%" }}>
                                <div >
                                    <div className="pin">1</div>
                                </div>
                                <div className="ps-2">
                                    <div className="underline">Contact us</div>
                                    <div className="small">and describe your problematics</div>
                                </div>

                            </div>
                            <div className="d-flex flex-row" style={{ width: "15%" }}>
                                <div >
                                    <div className="pin">2</div>
                                </div>
                                <div className="ps-2">
                                    <div className="">We met and discuss</div>
                                    <div className="small">your problematic</div>
                                </div>
                            </div>
                            <div className="d-flex flex-row" style={{ width: "20%" }}>
                                <div >
                                    <div className="pin">3</div>
                                </div>
                                <div className="ps-2">
                                    <div className="">We send you</div>
                                    <div className="small">a proposal solution</div>
                                </div>

                            </div>
                            <div className="d-flex flex-row" style={{ width: "30%" }}>
                                <div >
                                    <div className="pin">4</div>
                                </div>
                                <div className="ps-2">
                                    <div className="">You say "Go for it"</div>
                                </div>

                            </div>
                            <div className="d-flex flex-row" style={{ width: "20%" }}>
                                <div >
                                    <div className="pin">5</div>
                                </div>
                                <div className="ps-2">
                                    <div className="text-black">We create your solution</div>
                                    <div className="small text-dark">Fluorescent labelling
                                        Deuteration of product</div>
                                </div>

                            </div>
                        </div>
                        <div className="py-5" style={{ width: "80%", zIndex: 4 }}>
                            <div className="w-100 text-center text-white small">2 weeks time</div>
                            <div className="d-flex flex-row w-100 arrow">
                                <div className="pin bg-white"></div>
                                <div className="w-100 thick"></div>
                                <FontAwesomeIcon className="text-white" style={{ margin: "2px -1" }} icon={faArrowRight} />
                            </div>
                        </div>
                        <div className="pb-5"></div>

                    </Container>


                </div>

                <Container className="py-5">
                    <Row>
                        <Col md={4}></Col>
                        <Col md={8}>
                            <h3 className="part-title py-4 text-dark text-end">Our products don't come "Naked". <span className="text-grey">We provide certifications, quality control and how-to-use guides.</span> </h3>
                        </Col>
                    </Row>
                    <div className="pt-4">
                        <Row >
                            <Col md={2}></Col>
                            <Col md={5} className="">
                                <div className="w-100 py-2 bg-starlight rounded text-center"><b>Standard services</b></div>
                                <Row className="py-2">
                                    <Col className="text-center">Quality Control and CoA<sup>1</sup></Col>
                                    <Col className="text-center">Exact weight service<sup>2</sup></Col>
                                </Row>
                            </Col>
                            <Col md={5}>
                                <div className="w-100 py-2 bg-starlight-dark text-white rounded text-center">Standard services</div>
                                <Row className="py-2">
                                    <Col className="text-center">Impurity profiling</Col>
                                    <Col className="text-center">User guides</Col>
                                    <Col className="text-center">Biological databases</Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="py-2 bg-light my-auto">
                            <Col md={2} className="my-auto">
                                Admet Products
                            </Col>
                            <Col md={5} className="text-center">
                                <Row className="">
                                    <Col className="text-center"><b>X</b></Col>
                                    <Col className="text-center"><b>X</b></Col>
                                </Row>
                            </Col>
                            <Col md={5}>
                                <Row className="">
                                    <Col className="text-center"><b>X</b></Col>
                                    <Col className="text-center"><b>X</b></Col>
                                    <Col className="text-center"><b>X</b><sup>3</sup></Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="py-1"></div>
                        <Row className="py-2 bg-light my-auto">
                            <Col md={2}>
                                Admet Libraries
                            </Col>
                            <Col md={5} className="">
                                <Row className="">
                                    <Col className="text-center"><b>X</b></Col>
                                    <Col className="text-center"></Col>
                                </Row>
                            </Col>
                            <Col md={5}>
                                <Row className="">
                                    <Col className="text-center"><b>X</b></Col>
                                    <Col className="text-center"></Col>
                                    <Col className="text-center"><b>X</b></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-3 my-auto">
                            <Col md={2}>

                            </Col>
                            <Col md={6} className="">
                                <p className="small m-0"><sup>1</sup> Annual product quality control for Powdered chemical product and bi-annual quality control for diluted chemical products.</p>
                                <p className="small pt-2 m-0"><sup>2</sup> Exact weight service for powdered products.</p>
                                <p className="small pt-2 m-0"><sup>3</sup> For innovative products only.</p>

                            </Col>

                        </Row>


                    </div>


                </Container>


                <Container className="py-5">
                    <h3 className="part-title py-4 text-dark">Focus on our services. </h3>
                    <Row className="py-3 mx-md-5 px-0 px-md-5 px-0 gx-5">
                        <Col md={6} className="px-5">
                            <p className="part-title subtitle">Standard services</p>
                            <p className="m-0">Standard quality control</p>
                            <p className="text-grey">Quality controls are performed by an analytical chemist in our laboratory based in France. Powdered and diluted chemical products are respectively tested once and twice a year. <br />#Transparency  ⎸We provide you with the full analytical data, not only the results : product purity (profile and percentages) and HPLC chromatogram.</p>
                            <p className="m-0">Standard CoA</p>
                            <p className="text-grey"> We provide with each item a certificate with essential information on the product : product description and characterisation, expiry date, storage conditions. <br />#Trust  ⎸We detail the whole value chain of the product from synthesis to delivery, to ensure traceability and confidence in our products.</p>
                            <p className="m-0">Exact weight service</p>
                            <p className="text-grey">Our exact weight service (precision up to 1/100mg) is free of charge - so the experimentation can start right away. We integrate this delicate step in our process for your own safety.</p>
                        </Col>
                        <Col md={6} className="px-5">
                            <p className="part-title subtitle">Premium services</p>
                            <p className="m-0">Impurity profiling</p>
                            <p className="text-grey">Convinced that a deeper analysis of your impurity profile will allow a better repeatability across your experimentations, we propose additional quality control analysis :
                                <br />&bull; 1H NMR spectrum analysis : structural evidence of your compounds.
                                <br />&bull; LC/MS spectrum analysis : complete description of impurities profile.
                                <br />&bull; 1H NMR + LC/MS.
                            </p>
                            <p className="text-grey">Our additional quality control services come with a premium CoA, describing the results.</p>
                            <p className="m-0">User Guides</p>
                            <p className="text-grey"> Our toxicologists make use of our products on a daily basis and are drafting specific protocols that we are happy to share with you.</p>
                            <p className="m-0">Biological databases</p>
                            <p className="text-grey">We make available our historical databases, for you to contextualise and compare your data set. </p>
                        </Col>
                    </Row>


                </Container>

                <Container className="py-5">
                    <h3 className="part-title py-4 text-dark text-end">Focus on genotoxic. <span className="text-grey">Selection for our [...].</span> </h3>
                    <ProductsPreview />
                </Container>
            </div>
        )
    }

}

export default Eurosafe

