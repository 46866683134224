import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
    Col, Row, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from "reactstrap";

import biopredicLight from '../statics/images/logos/biopredic-light.png'
import eurosafeLight from '../statics/images/logos/eurosafe-light.png'
import starlightLight from '../statics/images/logos/startlight-light.png'
import advancellsLight from '../statics/images/logos/advancells-light.png'

import biopredicLightWhite from '../statics/images/logos/biopredic-light-white.png'
import eurosafeLightWhite from '../statics/images/logos/eurosafe-light-white.png'
import advancellsLightWhite from '../statics/images/logos/advancells-light-white.png'

const Overbar = () => {

    const [isHoverBiopredic, setIsHoverBiopredic] = useState(false);
    const [isHoverAdvancells, setIsHoverAdvancells] = useState(false);
    const [isHoverEurosafe, setIsHoverEurosafe] = useState(false);

    const hoverBiopredicOn = () => setIsHoverBiopredic(true);
    const hoverAdvancellsOn = () => setIsHoverAdvancells(true);
    const hoverEurosafeOn = () => setIsHoverEurosafe(true);

    const hoverBiopredicOut = () => setIsHoverBiopredic(false);
    const hoverAdvancellsOut = () => setIsHoverAdvancells(false);
    const hoverEurosafeOut = () => setIsHoverEurosafe(false);



    return (

        <Navbar style={{zIndex: 999}} className="w-100 px-5 overbar my-auto d-md-block d-none " color="light" light expand="md">
            <Nav className=" my-auto " navbar>
                <NavItem className="text-end">
                    <Link className="nav-link py-0" to="starlight">
                        <div className='starlight-header'>
                            <Row className='p-0 m-0'>
                                <Col className='p-0 m-0 my-auto'><LazyLoadImage style={{ marginBottom: "2px" }} height="auto" width={20} src={starlightLight} /></Col>
                                <Col className='p-0 m-0' style={{ width: "10px" }}></Col>
                                <Col className='p-0 m-0'>Starlight</Col>
                            </Row>
                        </div>
                    </Link>

                </NavItem>
                <NavItem>
                    <Link className="nav-link py-0" to="biopredic" onMouseOver={hoverBiopredicOn} onMouseOut={hoverBiopredicOut}>
                        <div className='biopredic-header' >
                            <Row className='p-0 m-0'>
                                <Col className='p-0 m-0'><LazyLoadImage style={{ marginBottom: "2px" }} height="auto" width={17} src={isHoverBiopredic ? biopredicLightWhite : biopredicLight} /></Col>
                                <Col className='p-0 m-0' style={{ width: "10px" }}></Col>
                                <Col className='p-0 m-0'>Biopredic</Col>
                            </Row>
                        </div>
                    </Link>
                </NavItem>
                <NavItem className="text-left">
                    <Link className="nav-link py-0" to="eurosafe" onMouseOver={hoverEurosafeOn} onMouseOut={hoverEurosafeOut}>
                        <div className='eurosafe-header' >
                            <Row className='p-0 m-0'>
                                <Col className='p-0 m-0'><LazyLoadImage style={{ marginBottom: "2px" }} height="auto" width={17} src={isHoverEurosafe ? eurosafeLightWhite : eurosafeLight} /></Col>
                                <Col className='p-0 m-0' style={{ width: "10px" }}></Col>
                                <Col className='p-0 m-0' style={{ lineHeight: 1.5 }}>Eurosafe</Col>
                            </Row>
                        </div>
                    </Link>
                </NavItem>
                <NavItem className="text-left" style={{ whiteSpace: 'nowrap' }}>

                    <Link className="nav-link py-0" to="" onMouseOver={hoverAdvancellsOn} onMouseOut={hoverAdvancellsOut}>
                        <div className='advancells-header' >
                            <Row className='p-0 m-0'>
                                <Col className='p-0 m-0'><LazyLoadImage style={{ marginBottom: "2px" }} height="auto" width={17} src={isHoverAdvancells ? advancellsLightWhite : advancellsLight} /></Col>
                                <Col className='p-0 m-0' style={{ width: "10px" }}></Col>
                                <Col className='p-0 m-0'>Biorpedic advancells</Col>
                            </Row>
                        </div>
                    </Link>
                </NavItem>
            </Nav>
            <Nav>
                <NavItem className="text-end border-end">
                    <Link className="nav-link py-0 text-dark" to="contact">Contact</Link>
                </NavItem>
                <NavItem className="text-end border-end">
                    <Link className="nav-link py-0 text-dark" to="">Order history</Link>
                </NavItem>
                <NavItem className="text-end border-end">
                    <Link className="nav-link py-0 text-dark" to="login">Login</Link>
                </NavItem>
                <NavItem className="text-end">
                    <Link className="nav-link py-0 text-dark" to="register">Register</Link>
                </NavItem>
            </Nav>

        </Navbar>

    )
}


export default Overbar